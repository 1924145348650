import {React, useEffect} from "react";
import logo from "./logo.svg";
// import "./App.css";
import Home from "./pages/HomeNew";
import BlogHome from "./pages/BlogHome";
import BlogAll from "./pages/BlogAll";
import AiDoc from "./pages/aiDocPage/AiDoc";
import Uploaddocs from "./pages/Uploaddocs/Uploaddocs";
import DigitalSign from "./pages/digitalSign/DigitalSign";
import Marketplace from "./pages/marketplace/Marketplace";
import WalletAndExchange from "./pages/walletandexcahnge/WalletAndExchange";
import VirtualCourt from "./pages/VirtualCourt/VirtualCourt";
import Smartcontract from "./pages/Smartcontractpage/Smartcontract";
import Adr from "./pages/Adr/Adr";
import Mediotors from "./pages/Mediotors/Mediotors";
import LeagalChat from "./pages/leagalChat/leagalChat";

import AboutPage from "./pages/about/AboutPage";
import Ourteam from "./pages/about/ourteam";
import BlogDetails from "./pages/BlogDetails";
import ContactUs from "./pages/contact/ContactUs";
import Tokenomics from "./pages/Tockenomics/Tokenomics";
import Joincommunity from "./pages/community/Joincommunity";
import Partnerspage from "./pages/partners/Partnerspage";
import AlternateDisputePage from "./pages/AlternateDispute/AlternateDisputePage";
import DisputeResolutionPage from "./pages/DisputeResolution/DisputeResolutionPage";
import Soon from "./pages/Soon";

import {BrowserRouter, Routes, Route} from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ai-legal-document-generator" element={<AiDoc />} />
          <Route
            path="/secure-document-storage-blockchain"
            element={<Uploaddocs />}
          />
          <Route path="/digital-signature" element={<DigitalSign />} />
          <Route path="/laywer-marketplace" element={<Marketplace />} />
          <Route
            path="/lbt-supported-wallets-exchanges"
            element={<WalletAndExchange />}
          />
          <Route path="/virtual-courtroom" element={<VirtualCourt />} />
          <Route path="/smart-legal-contracts" element={<Smartcontract />} />

          <Route path="/arbitration" element={<Adr />} />
          <Route path="/Mediation" element={<Mediotors />} />
          <Route path="/ai-legal-chat" element={<LeagalChat />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/tokenomics" element={<Tokenomics />} />
          <Route path="/join-community" element={<Joincommunity />} />
          <Route path="/partners" element={<Partnerspage />} />
          <Route path="/comming-soon" element={<Soon />} />
          <Route path="/alternate-dispute-resolution" element={<AlternateDisputePage />} />
          <Route
            path="/dispute-resolution"
            element={<DisputeResolutionPage />}
          />

          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/our-team" element={<Ourteam />} />
          <Route path="/bloghome" element={<BlogHome />} />
          <Route path="/blog" element={<BlogAll />} />
          <Route path="/blogdetails/:slug" element={<BlogDetails />} />

          {/* <Route path="/refferal" element={<Refferal />} />
          <Route path="/applay" element={<Applayform />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question:
        "What are Smart Legal Contracts and how do they work on Law Blocks AI?",
      answer:
        "Smart legal contracts are blockchain-based agreements that automatically execute when predefined conditions are met. On the Law Blocks AI platform, users can purchase or upload legal documents using LBT and XDC tokens. Once a transaction occurs, the smart contract is executed automatically, ensuring transparency and security through blockchain technology.",
    },
    {
      question:
        "How does Law Blocks AI ensure the security of legal contracts?",
      answer:
        "Law Blocks AI leverages blockchain technology, which ensures that all transactions and documents are recorded immutably on a decentralized ledger. This guarantees that once a legal contract is stored, it cannot be altered or tampered with, providing advanced security for users.",
    },
    {
      question:
        "What are the benefits of using LBT and XDC tokens on Law Blocks AI?",
      answer:
        "LBT and XDC tokens streamline transactions on Law Blocks AI by enabling seamless, instant payments and withdrawals. They help automate the contract execution process, eliminate the need for intermediaries, and reduce costs associated with traditional legal services, offering a cost-effective and efficient solution.",
    },
    {
      question: "How does Law Blocks AI automate the legal process?",
      answer:
        "Law Blocks AI automates the legal process by using smart contracts that automatically execute when conditions are met. For example, when users purchase or upload legal documents, the smart contract is triggered and the transaction is completed without the need for human intervention. This eliminates errors, speeds up legal processes, and ensures reliable automation.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          Smart Legal Contracts and Blockchain Security on Law Blocks AI
        </title>
        <meta
          property="og:title"
          content="Smart Legal Contracts and Blockchain Security on Law Blocks AI"
        />
        <meta
          property="og:description"
          content="Explore how Law Blocks AI revolutionizes the legal process with Smart Legal Contracts, blockchain technology, and LBT & XDC tokens, ensuring security, efficiency, and cost-effectiveness."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
  <!-- <meta
    property="og:image"
    content="https://lawblocks.io/assets/smart-contract-thumbnail.jpg"
  /> --> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/smart-legal-contracts"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Smart Legal Contracts and Blockchain Security on Law Blocks AI"
        />
        <meta
          name="twitter:description"
          content="Discover how Law Blocks AI automates the legal process with Smart Contracts and ensures security through blockchain technology and LBT & XDC tokens."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
  <!-- <meta
    name="twitter:image"
    content="https://lawblocks.io/assets/smart-contract-thumbnail.jpg"
  /> --> */}
      </Helmet>

      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero padding_dsection"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={7}
                className="d-flex align-item-center justiy-content-center"
              >
                <div className="hero_seaction_law align-items-start justify-content-center fixed_hero_height content_h2_uyb">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Smart Legal Contracts on Law Blocks AI
                  </h1>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={5}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="hero_seaction_law align-items-start justify-content-center fixed_hero_height">
                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Blockchain-based token transaction and document security
                    using Law Blocks AI.
                  </p>
                  <Button
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    onClick={navigate}
                  >
                    Get Started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex align-items-center justify-content-center pt-5"
              >
                <div
                  className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    alt="Virtual Courtroom The Future of Digital Justice"
                    src={require("../../Image/banner/smart-banner/smartbanar.webp")}
                    className="img-fluid img_center_section_wallet margin_bottons_virtyua-"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <div className="">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas image_contain_digital"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="Virtual Courtroom The Future of Digital Justice"
                      src={require("../../Image/banner/smart-banner/whatsmaer.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>What Are Smart Legal Contracts?</h2>
                    <p>
                      Smart legal contracts are blockchain-based agreements that
                      automatically execute when conditions are met, eliminating
                      the need for intermediaries. This ensures faster,
                      error-free legal processes. On the Law Blocks AI platform,
                      users can seamlessly manage transactions with LBT and XDC
                      tokens, streamlining the purchase and storage of legal
                      documents.
                    </p>
                    <Button
                      className="laight_button border_radious_button"
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      onClick={navigate}
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt  bg-gray_section pt-4">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={9}>
                  <div
                    className="overvide align-items-center justify-content-center mb-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">
                      How Smart Legal Contracts Work
                    </h2>
                    <p className="text-center">
                      With Law Blocks AI, smart contracts are automatically
                      executed when users purchase or upload legal documents
                      using LBT and XDC tokens for transactions. 
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="logo_slide">
                  <div class="keyfetupload-grid item_white logo_barnd option_barnd">
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0 mb-3">
                        <img
                          src={require("../../Image/banner/smart-banner/feture-1.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Blockchain-Based</h3>
                      <p>
                        Smart contracts run on blockchain networks, ensuring
                        both security and immutability.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0 mb-3">
                        <img
                          src={require("../../Image/banner/smart-banner/futere-2.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Automated Execution</h3>
                      <p>
                        Contracts execute automatically when conditions like
                        document purchase or upload are met.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0 mb-3">
                        <img
                          src={require("../../Image/banner/smart-banner/feture-2.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Transparent and Secure</h3>
                      <p>
                        Every transaction is recorded on the blockchain,
                        providing full transparency to all parties.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={5}>
                  <div
                    className="overvide align-items-start justify-content-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Benefits of Smart Legal Contracts on Law Blocks AI</h2>

                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Try for free
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                  <div class="keyfetupload-grid ">
                    <div class="keyfetupload-item">
                      <h2>1</h2>
                      <h3>Automated Transactions</h3>
                      <p>
                         Document purchases and uploads are processed
                        automatically.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>2</h2>
                      <h3>Immutable Records</h3>
                      <p>
                        Blockchain ensures transactions are permanently recorded
                        and cannot be altered.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>3</h2>
                      <h3>Security & Transparency</h3>
                      <p>
                        Decentralized smart contracts provide top-tier security
                        and full transparency.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>4</h2>
                      <h3>Cost-Effective</h3>
                      <p>
                        Eliminating intermediaries reduces legal fees and saves
                        time.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>5</h2>
                      <h3>Token Integration</h3>
                      <p>
                        Transactions on Law Blocks AI are seamlessly managed
                        with LBT and XDC tokens.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt  why_digilat bg-gray_section">
          <Container maxWidth="xl">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/smart-banner/whyfeturesmart.webp")}
                      className="img-fluid"
                      alt="AI Legal Chat"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start pr-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Why Choose Law Blocks AI for Smart Legal Contracts?</h2>
                    <p>
                      Law Blocks AI offers a cutting-edge platform for managing
                      smart legal contracts, providing users with
                    </p>

                    <ul>
                      <li>
                        <b>Advanced Security</b>
                      </li>
                      <li>
                        <b>User-Friendly Interface</b>
                      </li>
                      <li>
                        <b>Token Integration</b>
                      </li>
                      <li>
                        <b>Reliable Automation</b>
                      </li>
                      <li>
                        <b>Cost-Effective Solution</b>
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;

import React from "react";

function Soon() {
  return (
    <>
      <div class="full-screen-bg">
        <div class="overlay">
          <div class="container">
            <div class="row">
              <div class="col-md-6 content">
                <h1>Coming Soon</h1>
                <p>
                  We're working hard to bring you something amazing. Stay tuned!
                </p>
                <a href="/" class="btn btn-custom">
                  Return Home
                </a>
              </div>
              <div className="col-mg-6"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Soon;

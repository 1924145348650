import React from "react";
import Slider from "react-slick";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Rating,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import testimg from "../../Image/banner/testimoni.jpeg";
const testimonials = [
  {
    name: "Victor Borisov",
    title: "IT Legal Service | Crypto Legal & Compliance officer",
    text:
      "Law Blocks AI is revolutionising the way we handle legal documents with its AI-driven solutions. Uploading and managing documents on the blockchain ensures utmost security and transparency.",
    rating: 4,
    image:
      "https://res.cloudinary.com/decbm52pl/image/upload/v1719736944/photo_2024-06-30_14-08-19_xeufg8.jpg",
  },
  {
    name: "Mr. Manish Kumar",
    title: "Early Bird Investor",
    text:
      "Switching from DocuSign and PandaDoc to Law Blocks AI for digital signatures has been a game-changer. The blockchain-backed process is seamless, secure, and future-proof. As an early investor, I’m confident that the Government of India will gradually adopt Web 3 technology, and Law Blocks AI is leading the way in this transformation.",
    rating: 4.5,
    image:
      "https://res.cloudinary.com/dapljmrfe/image/upload/v1724661075/qrjjphh9xpnyofutsuhg.jpg",
  },
  {
    name: "Nikolina Paljuh ",
    title:
      "Corporate lawyer at HEP | Member of the Board of Directors | Regional Coordinator of EU projects and funds.",
    text:
      "As a lawyer specialising in AI legalities, I find Law Blocks AI indispensable for managing and sharing cryptographic signatures securely.",
    rating: 5,
    image:
      "https://res.cloudinary.com/decbm52pl/image/upload/v1719736944/photo_2024-06-30_14-08-27_l268wz.jpg",
  },
  {
    name: "Fola Alade (ESQ.) ",
    title:
      "Global Mediator | Commercial Lawyer | Notary Public | Mediation Advocate | Business Negotiator | Neuro Linguistic Practitioner | Emotional Intelligence Specialist |Conflict Resolution Coach",
    text:
      "Law Blocks AI has streamlined our arbitration process globally, providing an efficient platform for alternate dispute resolution through mediation and arbitration.",
    rating: 4.5,
    image:
      "https://res.cloudinary.com/decbm52pl/image/upload/v1719736944/photo_2024-06-30_14-08-32_mht8kz.jpg",
  },

  {
    name: "Advocate Vipin Dubey ",
    title: "Family and Criminal Court",
    text:
      "Law Blocks AI has streamlined our arbitration process globally, providing an efficient platform for alternate dispute resolution through mediation and arbitration.",
    rating: 5,
    image:
      "https://res.cloudinary.com/decbm52pl/image/upload/v1719770224/photo_2024-06-30_23-26-28_yhonon.jpg",
  },
  {
    name: "Alok Shrivastava ",
    title: "Criminal Law",
    text:
      "The ability to upload and manage documents on the blockchain has brought us a new level of trust and security to our legal processes.",
    rating: 5,
    image:
      "https://res.cloudinary.com/decbm52pl/image/upload/v1719770525/photo_2024-06-30_23-28-50_hlxsxw.png",
  },
  {
    name: "Advocate Sreejith ",
    title: "Family and Criminal Court",
    text:
      "Law Blocks AI's legal document management on AI technology is a game-changer for the legal industry, providing country-specific solutions that cater to our unique needs.",
    rating: 5,
    image:
      "https://res.cloudinary.com/decbm52pl/image/upload/v1719770745/photo_2024-06-30_23-35-23_ftsfal.jpg",
  },
];

const NextArrow = ({onClick}) => (
  <div className="arrow next" onClick={onClick}>
    <i className="ri-arrow-right-line"></i>
  </div>
);

const PrevArrow = ({onClick}) => (
  <div className="arrow prev" onClick={onClick}>
    <i className="ri-arrow-left-line"></i>
  </div>
);

const TestimonialSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };

  return (
    <div className="testimonial-slider-container">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-slide">
            <Card
              sx={{
                maxWidth: 800,
                width: "100%",
                padding: "20px",
                display: "flex",
                alignItems: "center",
              }}
              className="card_dark slider_test"
            >
              <Avatar
                src={testimonial.image}
                alt={testimonial.name}
                sx={{width: 80, height: 80, marginRight: 2}}
              />
              <CardContent>
                <Typography variant="h6">{testimonial.name}</Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  className="mb-2"
                >
                  {testimonial.title}
                </Typography>
                <Rating
                  name="read-only"
                  value={testimonial.rating}
                  readOnly
                  sx={{marginBottom: 2}}
                />
                <Typography variant="body1">{testimonial.text}</Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;

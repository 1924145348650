import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is Law Blocks AI's Marketplace for Lawyers?",
      answer:
        "It's an online platform where legal professionals can offer their services, find clients, and access advanced legal tools.",
    },
    {
      question: "How does the Digital Signature feature work?",
      answer:
        "You can sign documents digitally using three different methods: uploading a signature, drawing one, or typing your name and selecting a font style.",
    },
    {
      question: "Can I contribute legal documents to the platform?",
      answer:
        "Yes, attorneys can create and upload their legal documents to the marketplace and earn LBT tokens with each purchase.",
    },
    {
      question: "Is Law Blocks AI secure?",
      answer:
        "Absolutely. We use advanced encryption and blockchain technology to ensure the security and integrity of your documents and transactions.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>Marketplace for Lawyers - Connects Lawyers with Clients</title>
        <meta
          property="og:title"
          content="Marketplace for Lawyers - Connects Lawyers with Clients"
        />
        <meta
          property="og:description"
          content="Simplify your legal journey with our marketplace. We are connecting Legal Professionals with Clients Who Need Them."
        />
        {/* <meta
    property="og:image"
    content="https://lawblocks.io/assets/lawyers-marketplace-thumbnail.jpg"
  /> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/lawyers-marketplace"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Marketplace for Lawyers - Connects Lawyers with Clients"
        />
        <meta
          name="twitter:description"
          content="Simplify your legal journey with our marketplace. We are connecting Legal Professionals with Clients Who Need Them."
        />
        {/* <meta
    name="twitter:image"
    content="https://lawblocks.io/assets/lawyers-marketplace-thumbnail.jpg"
  /> */}
      </Helmet>

      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc uploaddocs bg-white didgtal_hero marketplace_bg bg-gray_section bg-gray_section-hero"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div className="hero_seaction_law align-items-start ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Marketplace for Lawyers - Connects Lawyers with Clients
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Simplify your legal journey with our marketplace. We are
                    connecting Legal Professionals with Clients Who Need Them.
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Try for free
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              ></Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_defualt  pb-0">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                      src={require("../../Image/banner/laweere.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Lawyers Marketplace Overview</h2>
                    <p>
                      Welcome to the Marketplace for Lawyers by Law Blocks AI,
                      where the legal profession meets innovative technology.
                      Our platform is designed to make the legal process easy,
                      fast, and affordable for all parties involved—whether
                      you're an advocate, arbitrator, mediator, or a client
                      seeking legal services. At Law Blocks AI, we provide a
                      comprehensive Online Marketplace for Legal Services that
                      helps legal professionals with advanced tools and
                      features, transforming the way legal work is done.
                    </p>

                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    How the Law Blocks AI <br />
                    Marketplace Support Lawyers
                  </h2>
                </div>
              </Grid>
            </Grid>
            <div class="features-container pl-0 pr-0 pb-0">
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                    src={require("../../Image/banner/mediator.webp")}
                  />
                </div>
                <h3>Arbitration and Mediation</h3>
                <p>
                  Lawyers Marketplace on Law Blocks AI connects clients with
                  legal professionals, allowing advocates to be listed in
                  arbitration or mediation panels for easy access anytime,
                  anywhere.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                    src={require("../../Image/banner/electronic-signature.webp")}
                  />
                </div>
                <h3>Digital Signature for Easy Agreement Signing</h3>
                <p>
                  Our Digital Signature feature allows you to sign legal
                  documents securely from anywhere at any time, speeding up the
                  process and eliminating delays associated with paper-based
                  methods.
                </p>
              </div>
            </div>
            <div class="features-container pl-0 pr-0 pt-0">
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/ai-file.webp")}
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                  />
                </div>
                <h3>AI-Powered Legal Document Generation</h3>
                <p>
                  Law Blocks AI lets legal professionals upload and sell their
                  own legal documents, earning LBT tokens with each purchase,
                  while expanding their professional reach.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/salary.webp")}
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                  />
                </div>
                <h3>Contribute and Earn</h3>
                <p>
                  Legal professionals can add documents to the Law Blocks AI
                  marketplace and earn LBT tokens with each purchase, sharing
                  expertise while generating income.
                </p>
              </div>
            </div>
          </Container>
        </section>
        <section className="section_defualt  bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={5}>
                  <div
                    className="overvide align-items-start justify-content-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Advanced Tools for Legal Professionals</h2>
                    <p>
                      Law Blocks AI offers a suite of advanced tools tailored
                      specifically for the legal industry. Here’s how our
                      platform supports your legal practice
                    </p>
                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Try for free
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                  <div class="keyfetupload-grid item_white">
                    <div class="keyfetupload-item">
                      <h2>1</h2>
                      <h3>Legal Document Generating AI</h3>
                      <p>
                        Legal Documents can be generated with in a minute with
                        the help of Artificial intelligence.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>2</h2>
                      <h3>Legal AI Chat</h3>
                      <p>
                        Legal Professionals or people with legal doubts can chat
                        with the AI, through AI Legal Chat feature.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>3</h2>
                      <h3>Digital Signature</h3>
                      <p>
                        We make the process of document signing easy and fast
                        with Digital signature. From anywhere anytime.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>4</h2>
                      <h3>Connecting with Lawyers</h3>
                      <p>
                        People can connect with Lawyers from any country by
                        sending them requests.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h2>5</h2>
                      <h3>Secure document storage</h3>
                      <p>
                        Any documents can be stored securely and managed on the
                        blockchain with Law Blocks AI.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    The Benefits of Using Law Blocks AI <br />
                    for Legal Professionals
                  </h2>
                </div>
              </Grid>
            </Grid>
            <div class="features-container">
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/flash-2.webp")}
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                  />
                </div>
                <h3>Efficiency and Speed</h3>
                <p>
                  Complete legal tasks faster with Digital Signature and
                  AI-powered document generation.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/security.webp")}
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                  />
                </div>
                <h3>Security and Compliance</h3>
                <p>
                  Secure your documents with advanced encryption and blockchain
                  technology.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/finance-monetization.webp")}
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                  />
                </div>
                <h3>Monetize Your Expertise</h3>
                <p>
                  Earn LBT tokens by contributing and selling legal documents on
                  our platform.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/headphones.webp")}
                    alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                  />
                </div>
                <h3>Comprehensive Support</h3>
                <p>
                  Access tools for managing cases, documents, and client
                  communications.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <section className="section_defualt  why_digilat bg-gray_section">
          <Container maxWidth="xl">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="Marketplace for Lawyers - Connects Lawyers with Clients"
                      src={require("../../Image/banner/marketplac.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start pr-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Why Choose Law Blocks AI?</h2>
                    <p>
                      Law Blocks AI stands out as the Best Legal Software for
                      Lawyers because of its ability to integrate technology
                      seamlessly into the legal workflow. Here’s why you should
                      choose our platform
                    </p>

                    <ul>
                      <li>
                        <b>User-Friendly Interface</b>
                      </li>
                      <li>
                        <b>Versatile Tools</b>
                      </li>
                      <li>
                        <b>Global Reach</b>
                      </li>
                      <li>
                        <b>Ongoing Innovation</b>
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;

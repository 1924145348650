import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "Can I choose my arbitrator on Law Blocks AI?",
      answer:
        "Yes, Law Blocks AI allows parties to select from a pool of accredited arbitrators, ensuring you have control over who handles your dispute.",
    },
    {
      question: "Is the arbitration process legally binding?",
      answer:
        "Yes, the arbitration rulings made through Law Blocks AI are legally binding and enforceable under the rules of arbitration, providing a reliable resolution to disputes.",
    },
    {
      question: "What are the benefits of using Law Blocks AI for arbitration?",
      answer:
        "The main benefits include faster resolution times, cost efficiency, secure blockchain-based records, and access to a global network of arbitrators.",
    },
    {
      question: "How do I initiate an arbitration process on Law Blocks AI?",
      answer:
        "To start an arbitration, you simply need to upload your case details and supporting documents to the platform, select an arbitrator, and agree to the terms of the arbitration process.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          Alternative Dispute Resolution (ADR) through Arbitration with Law
          Blocks AI
        </title>
        <meta
          property="og:title"
          content="Alternative Dispute Resolution (ADR) through Arbitration with Law Blocks AI"
        />
        <meta
          property="og:description"
          content="Resolve disputes efficiently with Law Blocks AI's arbitration platform. Connect with certified arbitrators, streamline the arbitration process, and benefit from a cost-effective, AI-powered solution that ensures speedy, confidential, and expert-led resolutions."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  property="og:image"
  content="https://lawblocks.io/assets/adr-arbitration-thumbnail.jpg"
/> --> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/alternative-dispute-resolution"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Alternative Dispute Resolution (ADR) through Arbitration with Law Blocks AI"
        />
        <meta
          name="twitter:description"
          content="Discover how Law Blocks AI transforms arbitration for dispute resolution. Our AI-driven platform connects you with expert arbitrators, offering a modern, efficient, and cost-effective way to resolve disputes with confidentiality and ease."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/adr-arbitration-thumbnail.jpg"
/> --> */}
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc  bg-gray_section-hero heaight_excahnge section_fixed_height_sedcion banner_vierutal_hero"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={7}
                className="d-flex align-item-center justiy-content-center"
              >
                <div className="hero_seaction_law align-items-start justify-content-center fixed_hero_height">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Alternate Dispute Resolution (ADR) through Arbitration
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    With Law Blocks AI, you can contribute as a professional
                    arbitrator while helping parties resolve disputes
                    efficiently. Users select arbitrators who match their
                    specific legal needs.
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Get Started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={5}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    alt="Alternate Dispute Resolution (ADR) through Arbitration"
                    src={require("../../Image/banner/Adrhero.webp")}
                    className="img-fluid img_center_section_wallet"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas image_contain_digital"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="Alternate Dispute Resolution (ADR) through Arbitration"
                      src={require("../../Image/banner/Adr-2.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Arbitration</h2>
                    <p className="text-justify">
                      Arbitration is a dispute resolution process where a
                      neutral arbitrator makes a binding decision, similar to a
                      court ruling but faster. Unlike mediation, arbitration
                      offers a final resolution. Law Blocks AI connects you with
                      certified arbitrators, ensuring a fair and legally sound
                      outcome.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="section_defualt bg-gray_section pt-2">
          <Container maxWidth="lg" className="mt-5">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={8}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">
                      Benefits of Law Blocks AI Arbitration
                    </h2>
                    <p className="text-center">
                      Law Blocks AI Arbitration offers a modern, efficient
                      solution for dispute resolution. Discover the benefits of
                      choosing Law Blocks AI Arbitration today.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ul class="benifitlaw-list tranc_xlass justify-content-center">
                    <li>
                      <div class="circle">1</div>
                      <div class="text">Speedy Resolutions</div>
                    </li>
                    <li>
                      <div class="circle">2</div>
                      <div class="text">Cost-Effective</div>
                    </li>
                    <li>
                      <div class="circle">3</div>
                      <div class="text">Expert Arbitrators</div>
                    </li>
                    <li>
                      <div class="circle">4</div>
                      <div class="text">AI-Powered Platform</div>
                    </li>
                    <li>
                      <div class="circle">5</div>
                      <div class="text">Flexible and Convenient</div>
                    </li>
                    <li>
                      <div class="circle">6</div>
                      <div class="text">Global Access</div>
                    </li>
                    <li>
                      <div class="circle">7</div>
                      <div class="text">Confidential Proceedings</div>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    Arbitration Process with
                    <br />
                    Law Blocks AI
                  </h2>
                </div>
              </Grid>
            </Grid>
            <div class="features-container pl-0 pr-0">
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/login.webp")}
                    alt="Alternate Dispute Resolution (ADR) through Arbitration"
                  />
                </div>
                <h3>Sign Up and Log In</h3>
                <p>
                  Create an account and access arbitration services through a
                  user-friendly dashboard.
                </p>
              </div>

              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/lawer.webp")}
                    alt="Alternate Dispute Resolution (ADR) through Arbitration"
                  />
                </div>
                <h3>Choose Your Arbitrator</h3>
                <p>
                  Select an experienced arbitrator based on expertise and
                  location.
                </p>
              </div>
              <div class="feature-box ">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/chat.webp")}
                    alt="Alternate Dispute Resolution (ADR) through Arbitration"
                  />
                </div>
                <h3>Send Request</h3>
                <p>
                  Connect with the Arbitrator by sending a request through their
                  profile.
                </p>
              </div>
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/submit.webp")}
                    alt="Alternate Dispute Resolution (ADR) through Arbitration"
                  />
                </div>
                <h3>Submit Your Case</h3>
                <p>
                  Provide case details, initiating the arbitration process
                  through the platform.
                </p>
              </div>
            </div>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section pt-2 pb-5">
          <Container maxWidth="lg" className="mt-5">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={9}>
                  <div
                    className="overvide align-items-center"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">Contribute as an Arbitrator</h2>
                    <p className="text-center">
                      Experienced attorneys can join Law Blocks AI as
                      arbitrators, helping resolve disputes efficiently while
                      expanding their legal practice. Contribute your expertise
                      to our growing platform.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ul class="benifitlaw-list justify-content-center steps_to_create">
                    <li>
                      <div class="circle">1</div>
                      <div class="text">Sign Up as an Advocate</div>
                    </li>
                    <li>
                      <div class="circle">2</div>
                      <div class="text">Create Your Profile</div>
                    </li>
                    <li>
                      <div class="circle">3</div>
                      <div class="text"> Begin Arbitrating</div>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt  why_digilat">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Why Choose Law Blocks AI for Arbitration?</h2>
                    <p className="text-justify">
                      Law Blocks AI offers a fast and affordable way to resolve
                      disputes through online arbitration. Our platform makes
                      the process easy, from selecting expert arbitrators to
                      scheduling hearings, ensuring quick resolutions. Choose
                      from a wide range of qualified arbitrators, each
                      specialized in different legal fields, to get the best
                      outcome.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className=" "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/adr_why_bottom.webp")}
                      className="img-fluid"
                      alt="Alternate Dispute Resolution (ADR) through Arbitration"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default Home;

import React from "react";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import {Doughnut} from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: [
    "Ecosystem",
    "Founders",
    "Exchange listing and MM",
    "Contingency",
    "Private sale",
    "Core team",
  ],
  datasets: [
    {
      label: "# of Tokens Distribution",
      data: [40, 20, 18, 10, 10, 2],
      backgroundColor: [
        "#A0522D", // Ecosystem (sienna)
        "#8B4513", // Founders (saddle brown)
        "#CD853F", // Exchange listing and MM (peru)
        "#D2691E", // Contingency (chocolate)
        "#8B4513", // Private sale (saddle brown)
        "#5C4033", // Core team (dark brown)
      ],
      borderColor: [
        "#212121", // Borders for all sections
        "#212121",
        "#212121",
        "#212121",
        "#212121",
        "#212121",
      ],
      borderWidth: 1,
    },
  ],
};

function Chart() {
  return (
    <div className="chart_widyth">
      {" "}
      <Doughnut data={data} />
    </div>
  );
}

export default Chart;

import React, {useEffect, useState} from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import {LocationOn, Email, Telegram} from "@mui/icons-material";
import axios from "axios";

const ContactUs = () => {
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    mobile: "",
    advocate: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const sortedCountries = data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
        setCountries(sortedCountries);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // Handle input change
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate inputs
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    if (!formData.advocate) newErrors.advocate = "Please select an option";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length === 0) {
      var response = await axios.post(
        "https://market.lawblocks.io:5001/admin/admin/addcontact",
        formData
      );
      if (response) {
        alert(response.data.Message);
        window.location.reload();
      }
      console.log("Form Data:", response);
      // You can add further logic here (e.g., sending the data to a server)
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
      <Container
        style={{
          padding: "2rem",
          borderRadius: "12px",
        }}
        className=" contact_fonr"
      >
        <Grid container spacing={4} className="justify-content-center">
          <Grid item xs={12} md={9} className="d-flex align-items-center">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    className="bg-white input_typr_text mb-3"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    placeholder="Enter Email"
                    className="bg-white mb-3 input_typr_text"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl
                        fullWidth
                        error={!!errors.country}
                        className="select_s bg-white mb-3 input_typr_text"
                      >
                        <InputLabel className="dark_name">Country</InputLabel>
                        <Select
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          className="select_icon"
                        >
                          {countries.map((country) => (
                            <MenuItem key={country.cca2} value={country.cca2}>
                              <img
                                src={country.flags.svg}
                                alt={`${country.name.common} flag`}
                                style={{
                                  width: 24,
                                  height: 16,
                                  marginRight: 10,
                                }}
                              />
                              {country.name.common}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.country && (
                          <Typography color="error">
                            {errors.country}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d{0,15}$/.test(value)) {  // Regular expression to allow only up to 10 digits
                          handleChange(e);               // Only call handleChange if valid input
                        }
                      }}
                      error={!!errors.mobile}
                      helperText={errors.mobile}
                      placeholder="Enter Mobile Number"
                      className="bg-white mb-3 input_typr_text"
                      inputProps={{ maxLength: 15 }}  // Set a max length of 10 characters
                      type="tel"  // Use 'tel' to only allow numbers and show a numeric keyboard on mobile
                    />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.advocate}
                    className="select_s bg-white mb-3 input_typr_text"
                  >
                    <InputLabel className="dark_name">
                      Are you an advocate?
                    </InputLabel>
                    <Select
                      name="advocate"
                      value={formData.advocate}
                      onChange={handleChange}
                      className="select_icon"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                    {errors.advocate && (
                      <Typography color="error">{errors.advocate}</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    error={!!errors.message}
                    helperText={errors.message}
                    multiline
                    rows={4}
                    placeholder="Enter your message"
                    className="bg-white mb-1 input_typr_text"
                  />
                </Grid>
                <Grid item xs={12} className="d-flex justify-content-center">
                  <Button
                    className="laight_button"
                    variant="contained"
                    type="submit"
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContactUs;

import React, { useState, useEffect } from "react";
import Header from "./componant/HeaderNew";
import { Button } from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import BlogCard from "./componant/BlogCard";
// import Blogtabs from "./componant/Blogtabs";
function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // adaptiveHeight: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const { top } = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  // Regular Expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault(); // Prevent form submission

    // Email validation
    if (!email) {
      setError('Email is required.');
    } else if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
    } else {
      setError(''); // Clear any previous error

      // Perform subscription logic here (e.g., API call)
      console.log('Subscribed with email:', email);
      alert(`Subscribed with email: ${email}`);
    }
  };
  const posts = [
    {
      slug:
        "marketplace-for-lawyers-a-new-era-of-legal-service-delivery-law-blocks-ai",
      title:
        "Marketplace for Lawyers: A New Era of Legal Service Delivery - Law Blocks AI",
      category: "Marketplace for Lawyers",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI is transforming legal service delivery with a new marketplace for lawyers. Discover the benefits of AI-powered legal solutions, streamlined processes, and enhanced client-lawyer interactions.",

      likes: 220,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/Marketplace_slfuz1.jpg",
    },
    {
      slug: "benefits-of-ai-chat-for-legal-professionals-and-clients",
      title: "The Benefits of AI Chat for Legal Professionals and Clients",
      category: "Global Information AI",
      date: "July 12, 2024",
      description:
        "Discover the advantages of AI chat for legal professionals and clients with Law Blocks AI. Learn how AI-powered chat enhances communication, efficiency, and client satisfaction in the legal industry.",

      likes: 430,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ai_chat_sisdav.jpg",
    },
    {
      slug: "how-digital-signature-features-work-on-law-blocks-ai",
      title: "How Digital Signature Features Work on Law Blocks AI",
      category: "Digital Signature",
      date: "July 12, 2024",
      description:
        "Learn how Law Blocks AI enhances digital signature features to provide secure, efficient, and legally binding document signing. Explore the benefits of digital signatures in streamlining legal processes and ensuring document integrity.",

      likes: 650,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ds_thnxrv.jpg",
    },
    {
      slug: "ai-legal-document-creation-with-law-blocks-ai",
      title: "AI Legal Document Creation with Law Blocks AI",
      category: "AI Legal Document",
      description:
        "Explore how Law Blocks AI revolutionizes legal document creation with advanced AI technology. Discover efficient, accurate, and secure solutions for drafting legal documents, contracts, and agreements.",

      date: "July 12, 2024",
      likes: 120,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/AIdoc_mtapec.jpg",
    },

    {
      slug: "how-secure-are-the-documents-stored-on-law-blocks-ai",
      title: "How Secure Are the Documents Stored on Law Blocks AI?",
      category: "Uploading on blockchain",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI uses blockchain technology to ensure the security, integrity, and transparency of stored legal documents. Learn about advanced document security features and how they protect sensitive information.",
      likes: 150,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/LAw_Blocks_AI_Docu_cnklbl.jpg",
    },
    {
      slug:
        "simplifying-adr-through-arbitration-and-mediation-with-law-blocks-ai",
      title:
        "Simplifying ADR through Arbitration and Mediation with Law Blocks AI",
      category: "Mediation and Arbitration",
      description:
        "Discover how Law Blocks AI simplifies Alternative Dispute Resolution (ADR) through advanced AI-powered arbitration and mediation solutions. Learn about efficient, secure, and effective legal technology for resolving disputes.",
      date: "July 12, 2024",
      likes: 180,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/arb_and_mediation_iz4boo.jpg",
    },
  ];

  return (
    <div className={isScrolled ? "header scrolled" : "header "}>
      <Header />
      <main className="blog_body">
        <section className="section_defualt  mt-90" id="section-4">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className=" mb-3">
                <div
                  className=" overvide headeing_main align-items-center articelee
"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center mb-0">
                    All articles
                  </h2>
                </div>
              </Grid>
              {posts.map((post, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className="d-flex justify-content-center"
                  data-aos="fade-up"
                >
                  <BlogCard
                    key={index}
                    title={post.title}
                    description={post.description}
                    imagePath={post.imagePath}
                    category={post.category}
                    date={post.date}
                    slug={post.slug}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>

        <section className="section_defualt bg-gray_section">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className="">
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center mb-2">
                    Leading the Way in AI Legal Technology
                  </h2>
                  <p>Get the latest product and management insights.</p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className=" mb-4">
                <div
                  className="input_fore"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  <button onClick={handleSubscribe}>Subscribe</button>
                </div>
                <div className="input_fore">{error && <p style={{ color: 'red' }}>{error}</p>}</div>

              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
